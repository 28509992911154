import Modal from '@trendmicro/react-modal';
import * as React from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  Segment
} from 'semantic-ui-react';
import './_Scopebar.scss';
import { ModalBodyComponentProps } from 'components/ScopebarOption/ScopebarOptionModal';
import { head, isEmpty, mapValues, pickBy, startCase } from 'lodash';
import { useHandleKeyPress } from 'utils/component/hooks/hooks';
import { AppState, AppThunkDispatch } from 'store';
import { forceRefreshGrid, setEop } from 'state/scope/scope.actions';
import { findEarliestPlan, findEarliestPlans, getLastTimeMember } from './ScopeUtils';
import { SCOPETYPE_ACTUALS, TIME } from 'utils/domain/constants';
import { isReady } from 'state/scope/Scope.types';
import { memberToDropdown } from 'components/Scopebar/Scopebar.container';
import { ReseedPlanModalOwnProps } from 'components/Reseed/ReseedPlanModal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { TopMembers } from 'services/Scope.client';
import { getScopeObject, planFromSpace } from 'components/Scopebar/ScopeUtils';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';

const mapStateToProps = (state: AppState) => {
  const { scope, settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  if (!isReady(scope)) {
    return {
      seedOptions: undefined
    };
  }

  const availableMembers = state.viewConfigSlice.availableMembers;
  const timeMembers = availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : [];
  const earliestPlan = findEarliestPlan([...scope.mainConfig.initializedPlans, ...scope.mainConfig.uninitializedPlans]);

  let beforeTime: string | undefined = undefined;
  if (timeMembers && !isEmpty(timeMembers) && earliestPlan) {
    beforeTime = getLastTimeMember(timeMembers, earliestPlan.space.time)[0].value as string;
  }
  const earliestPlans = findEarliestPlans(scope.mainConfig.initializedPlans);
  const anchor = getScopeObject(scope.mainConfig.memberTrees);
  const onlyEarliestAnchor = mapValues(anchor, ((v, k) => {
    return k === TIME ? v.filter(m => m.id === earliestPlan.space.time) : v;
  }));

  return {
    initializedPlans: findEarliestPlans(scope.mainConfig.initializedPlans),
    balanceTimeOptions: getLastTimeMember(timeMembers, earliestPlan.space.time),
    balanceTime: beforeTime,
    eopOptions: scope.eopOptions,
    anchor: onlyEarliestAnchor,
    dimensionLabel
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    updateEop: (
      applyTo: number,
      eopId: number | string,
    ) => {
      return dispatch(setEop(applyTo, eopId))
        .then(() => dispatch(forceRefreshGrid()));
    }
  };
};

export type EopToBopModalValueProps = ReturnType<typeof mapStateToProps>;
export type EopToBopModalDispatchProps = ReturnType<typeof mapDispatchToProps>;
type EopToBopModalProps = ModalBodyComponentProps &
EopToBopModalValueProps &
EopToBopModalDispatchProps & ReseedPlanModalOwnProps;

const EopToBopModal = (props: EopToBopModalProps) => {
  const {
    initializedPlans,
    onCancel,
    onOpen,
    balanceTimeOptions,
    balanceTime,
    eopOptions,
    onSubmit,
    updateEop,
    anchor,
    dimensionLabel
  } = props;
  const buttonRef = useRef<HTMLDivElement>(null)
  const loading = isEmpty(balanceTimeOptions);
  const [mutationPending, setMutationPending] = useState(false);

  const [selectedBalanceTime, setSelectedBalanceTime] = useState<string | undefined>(balanceTime);

  const [
    currentBalancePlanId,
    setCurrentBalancePlanId
  ] = useState<PlanId | null>(head(initializedPlans) ? head(initializedPlans)!.id : null);
  const handleChangeBalancePlanId = useCallback((newMembers: TopMembers) => {
    if (!initializedPlans) { return; }
    setCurrentBalancePlanId(planFromSpace(initializedPlans, newMembers).id);
  }, [initializedPlans, setCurrentBalancePlanId]);

  const balanceVersionOptions = useMemo(() => {
    return !isEmpty(eopOptions) && currentBalancePlanId ? eopOptions![Number(currentBalancePlanId)].map((opt) => {
      return opt.seedType === SCOPETYPE_ACTUALS ? {
        text: startCase(SCOPETYPE_ACTUALS),
        value: opt.seedTime
      } : {
        text: opt.name,
        value: opt.planId
      };
    }) : undefined;
  }, [eopOptions, currentBalancePlanId]);

  const [selectedBalanceVersion, setSelectedBalanceVersion] = useState<number | string | undefined>(
    head(balanceVersionOptions) ? head(balanceVersionOptions)?.value : undefined);

  useEffect(() => {
    const currentSelectionInOptions = balanceVersionOptions?.find((opt) => opt.value === selectedBalanceVersion);
    if (!selectedBalanceVersion || !currentSelectionInOptions) {
      setSelectedBalanceVersion(head(balanceVersionOptions) ? head(balanceVersionOptions)?.value : undefined);
    }

    const submitButton = buttonRef.current
    if(submitButton){
      submitButton.focus();
    }
  }, [balanceVersionOptions, eopOptions, selectedBalanceVersion, currentBalancePlanId]);


  const handleChangeBalanceVersion = useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const newVersion = data.value;
      if (newVersion && (typeof newVersion === 'number' || typeof newVersion === 'string')) {
        setSelectedBalanceVersion(newVersion);
      }
    }, [setSelectedBalanceVersion]);

  const handleSubmit = useCallback(() => {
    if (!selectedBalanceVersion || mutationPending || !initializedPlans || !currentBalancePlanId) { return; }
    setMutationPending(true);
    updateEop(currentBalancePlanId, selectedBalanceVersion).then(() => {
      setMutationPending(false);
      onSubmit();
    }).catch(() => {
      toast.error('An error occured copying EOP to BOP');
      setMutationPending(false);
    });
  }, [selectedBalanceVersion, mutationPending, initializedPlans, currentBalancePlanId, updateEop, onSubmit]);
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <React.Fragment>
      <div className="initialize-plan" >
        <Grid doubling={true} stretched={true}>
          <Grid.Column>
            <div className="dropdown-group">
              <Segment>
                Select the Plan period and version to to Copy End of Plan data into the beginning of your WP
              </Segment>
              <div>
                {eopOptions && dimensionLabel && anchor ?
                  <AnchorRadioSelect
                    labelDimenion={dimensionLabel}
                    anchor={anchor}
                    onUpdateAnchorSelections={handleChangeBalancePlanId}
                  /> :
                  null}
              </div>
              <Dropdown
                fluid={true}
                loading={loading}
                data-qa="eop-period-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={balanceTimeOptions}
                value={selectedBalanceTime}
              />
            </div>
            <div className="dropdown-group">
              <div className="dropdown-group-label">
                Select the Plan Version
              </div>
              <Dropdown
                fluid={true}
                loading={loading}
                data-qa="eop-version-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={balanceVersionOptions}
                value={selectedBalanceVersion}
                onChange={handleChangeBalanceVersion}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Cancel" onClick={onCancel} />
        <div ref={buttonRef} className="submit-button-modal" tabIndex={0} onKeyPress={handleEnterPress}>
          <Button
            content="Submit"
            className="import-version-modal-button"
            data-qa="eop-copy-btn-submit"
            onClick={handleSubmit}
            loading={loading || mutationPending}

          />
        </div>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EopToBopModal);
