import { AxiosInstance } from 'axios';
import {
  TServerScopeResponse
} from '../state/scope/Scope.types';
import type {
  ServerScopeResponse
} from '../state/scope/Scope.types';
import {
  PerspectivePaths,
  SCOPETYPE_ACTUALS,
  SCOPETYPE_PLAN
} from '../utils/domain/constants';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import { makeClientDecoder, makeErrorLog } from 'components/PivotConfigurator/utils';
import { Space } from 'space';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import * as tPromise from 'io-ts-promise';
import LoggingService from '../services/Logging.service';
import { Workflows } from 'state/scope/codecs/Workflows';
import { SeedActuals, SeedPlan } from 'state/scope/ScopeManagement.slice';

export interface ScopeTarget {
  id: string,
  display: string
}

export const SCOPECREATE_WITH_WP = 'with-wp';
export const SCOPECREATE_WITHOUT_WP = 'without-wp';
export const SCOPECREATE_SMARTPLAN = 'with-smart-plan';
type SCOPECREATE_TYPES = typeof SCOPECREATE_WITH_WP | typeof SCOPECREATE_WITHOUT_WP | typeof SCOPECREATE_SMARTPLAN;
export type ScopeCreateRequest = {
  initParams: {
    type: SCOPECREATE_TYPES,
    name?: string
  },
  anchor: TopMembers,
  workflow: Workflow
}

export type Workflow = 'in-season' | 'pre-season';

export type TopMembers = Space<string[]>;

export interface ScopeMemberInfo {
  readonly id: string,
  readonly name: string,
  readonly description: string,
  readonly level: string,
  readonly inSeason?: boolean
}

export interface AvailableMembers {
  space: Space<ReadonlyArray<ScopeMemberInfo>>,
  controlPoints: { [key: string]: Space<ReadonlyArray<ScopeMemberInfo>> },
  inSeason: string,
  maxSelections: Space<number>
}

export interface SummarizedMetric {
  revision: string,
  metric: string,
  value: number
}

export const MODULES_BASE = `${API_BASE_URL}/modules`;
export const CONTEXT_BASE = `${API_BASE_URL}/context`;

export default class Scope {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (error: Error) => Error;
  public static logAsyncOrDecoderError = (logger: LoggingService, error: Error): never => {
    if (tPromise.isDecodeError(error)) {
      logger.error('Error: An error occued decoding a shape', error);
      throw new Error('Error: An error occued decoding a shape');
    }
    // otherwise, let the downstream error handler get it
    logger.error('Error: An unknown error occued downloading a resource', error);
    throw new Error('Error: An unknown error occued downloading a resource');
  };

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (error: Error) => Scope.logAsyncOrDecoderError(this.logger, error);
  }

  public createScope(create: ScopeCreateRequest, perspectivePath: PerspectivePaths): Promise<ServerScopeResponse> {
    const jsonStr = JSON.stringify(create);
    // @ts-ignore the type for ServerScopeResponse doesn't actually infer correctly from the codec :(
    return this.client
      .post<unknown>(`${MODULES_BASE}/${perspectivePath}`, jsonStr)
      .then(makeClientDecoder(TServerScopeResponse))
      .then((r) => r.data)
      .catch(makeErrorLog('failed to create scope'));
  }

  public getScope(scopeId: string) {
    return this.client
      .get<ServerScopeResponse>(`${CONTEXT_BASE}/${scopeId}`)
      .then(makeClientDecoder(TServerScopeResponse))
      .then((r) => r.data)
      .catch(makeErrorLog('failed tp fetch scope'));
  }

  public getAvailableMembers(perspectivePath: PerspectivePaths) {
    return this.client
      .get<AvailableMembers>(`${MODULES_BASE}/${perspectivePath}`)
      .then(response => response.data);
  }

  public seedScope(
    scopeId: string,
    seed: SeedPlan | SeedActuals
  ): Promise<{}> {
    const payload = seed.seedType === SCOPETYPE_ACTUALS ?
      {
        type: 'seed',
        source: {
          type: seed.seedType,
          time: seed.seedTime
        }
      } : {
        type: 'seed',
        source: {
          type: seed.seedType,
          id: seed.planId // TODO: check decoding type
        }
      };
    const jsonStr = JSON.stringify(payload);
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/workflows/${seed.applyTo}`, jsonStr);
  }

  public importVersion(
    scopeId: string,
    importId: number,
    applyTo: PlanId
  ): Promise<{}> {
    const jsonStr = JSON.stringify({
      type: 'import',
      source: {
        type: SCOPETYPE_PLAN,
        id: importId
      }
    });
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/workflows/${applyTo}`, jsonStr);
  }


  public getScopeLockState(scopeId: string): Promise<boolean> {
    return this.client
      .get<boolean>(`${CONTEXT_BASE}/${scopeId}/locked`)
      .then(response => response.data);
  }

  public unlockScopeLockState(scopeId: string): Promise<boolean> {
    return this.client
      .post<boolean>(`${CONTEXT_BASE}/${scopeId}/locked`)
      .then(response => {
        if (response.status === 204) {
          return false;
        }
        return response.data;
      });
  }

  public postOverlay(scopeId: string, applyTo: PlanId, overlayId: string): Promise<{}> {
    const jsonStr = JSON.stringify({
      type: 'overlay',
      source: overlayId
    });
    return this.client.post<{}>(`${CONTEXT_BASE}/${scopeId}/workflows/${applyTo}`, jsonStr)
      .then(d => d.data);
  }

  public saveVersion(scopeId: string, versionName: string | null) {
    const payload = JSON.stringify({ name: versionName });
    return this.client.post<void>(`${CONTEXT_BASE}/${scopeId}/save`, payload)
      .catch((err) => this.logError(err));
  }

  public saveSmartPlanVersion(scopeId: string, versionName: string | null): Promise<{}> {
    const payload = JSON.stringify({ name: versionName });
    return this.client.post<{}>(`${CONTEXT_BASE}/${scopeId}/smartsave`, payload)
      .catch((err) => this.logError(err));
  }

  public setEop(
    scopeId: string,
    applyTo: PlanId,
    eopIdOrYear: PlanId | string,
    eopType: typeof SCOPETYPE_ACTUALS | typeof SCOPETYPE_PLAN
  ): Promise<{}> {
    const payload = eopType === SCOPETYPE_ACTUALS ?
      {
        type: 'balance',
        source: {
          type: eopType,
          time: eopIdOrYear
        }
      } : {
        type: 'balance',
        source: {
          type: eopType,
          id: eopIdOrYear
        }
      };
    const jsonStr = JSON.stringify(payload);
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/workflows/${applyTo}`, jsonStr);
  }

  public undoScope(scopeId: string): Promise<void> {
    return this.client
      .post<void>(`${CONTEXT_BASE}/${scopeId}/undo`)
      .then(response => response.data);
  }

  public redoScope(scopeId: string): Promise<void> {
    return this.client
      .post<void>(`${CONTEXT_BASE}/${scopeId}/redo`)
      .then(response => response.data);
  }

  public attach(scopeId: string, planId: number, callback?: () => void): Promise<{}> {
    const payload = {
      planId,
      revName: 'ty-review-approved'
    };
    const jsonStr = JSON.stringify(payload);
    return this.client
      .post<{}>(`${API_BASE_URL}/context/${scopeId}/attach`, jsonStr)
      .then(response => {
        if (callback) {
          callback();
        }
        return response.data;
      });
  }

  public getPublishVersions(scopeId: string, applyTo: PlanId) {
    return this.client
      .get<string[]>(`${CONTEXT_BASE}/${scopeId}/publish/${applyTo}`)
      .then(resp => resp.data)
      .catch(this.logError);
  }

  public publishToVersion(scopeId: string, versionId: string, applyTo: PlanId): Promise<Error | undefined> {
    return this.client
      .post(`${CONTEXT_BASE}/${scopeId}/publish/${applyTo}`, `"${versionId}"`) // extra quotes required here
      .then(() => undefined)
      .catch(this.logError);
  }

  public getWorkflows(scopeId: string, applyTo: PlanId) {
    return this.client
      .get(`${CONTEXT_BASE}/${scopeId}/workflows/${applyTo}`)
      .then(response => tPromise.decode(Workflows, response.data))
      .catch(this.logError);
  }
}
