import { CSSProperties } from 'react';
import { CellComment } from './Comment';

export const CELL_TAG_FROZEN = 'frozen';
export const CELL_TAG_LOCKED = 'locked';
export const CELL_TAG_RELAXED = 'relaxed';

export const CELL_TAG_UNEDITABLE = 'uneditable';
export const CELL_TAG_DISABLED = 'disabled';
export const CELL_TAG_LOCKABLE = 'lockable';

export type CELL_LOCK_STATES = typeof CELL_TAG_LOCKED | typeof CELL_TAG_FROZEN | typeof CELL_TAG_RELAXED;

export interface CellProps {
  formula?: string,
  displayValue?: string,
  isLoaded?: boolean,
  isCalculating?: boolean,
  isLocked?: boolean, // doesn't exist anymore
  isConstrained?: boolean, // doesn't exist anymore
  isDisabled?: boolean, // doesn't exist anymore
  isAlwaysDisabled?: boolean, // doesn't exist anymore
  isLockable?: boolean, // this only applies to some calc metrics, does not work for revision cells
  needsReload?: boolean,
  value?: string,
  style?: CSSProperties,
  comments?: CellComment[],
  row: number,
  col: number,
  tags?: string[],
  advisoryTags?: string[],
  metricId: string,
  currencyId: string | null
}

export class PivotCell {
  public readonly row: number;
  public readonly col: number;
  public formula: string;
  public displayValue: string;
  public isLoaded: boolean;
  public needsReload: boolean;
  public value: string | undefined;
  public style: CSSProperties;
  public isCalculating: boolean;
  public comments: CellComment[];
  public tags: string[];
  public advisoryTags: string[];
  public metricId: string;
  public currencyId: string | null;

  public constructor(props?: CellProps) {
    const {
      formula = null,
      displayValue = null,
      isLoaded = false,
      needsReload = false,
      isCalculating = false,
      value = null,
      style = {},
      comments = [],
      tags = [],
      advisoryTags = [],
      currencyId = null,
      metricId,
      row,
      col
    } = props!;
    this.formula = formula!;
    this.displayValue = displayValue!;
    this.isLoaded = isLoaded;
    this.needsReload = needsReload;
    this.value = value || undefined;
    this.style = style;
    this.isCalculating = isCalculating;
    this.comments = comments;
    this.tags = tags;
    this.advisoryTags = advisoryTags;
    this.metricId = metricId;
    this.currencyId = currencyId;

    this.row = row;
    this.col = col;
  }

  public clone() {
    return new PivotCell({
      formula: this.formula,
      displayValue: this.displayValue,
      isLoaded: this.isLoaded,
      needsReload: this.needsReload,
      isCalculating: this.isCalculating,
      value: this.value,
      style: { ...this.style },
      comments: this.comments.slice(0),
      tags: this.tags,
      advisoryTags: this.advisoryTags,
      metricId: this.metricId,
      row: this.row,
      col: this.col,
      currencyId: this.currencyId
    });
  }

  public copyWithNewPos(row: number, col: number) {
    return new PivotCell({
      row,
      col,
      metricId: this.metricId,
      tags: this.tags,
      advisoryTags: this.advisoryTags,
      formula: this.formula,
      displayValue: this.displayValue,
      isLoaded: this.isLoaded,
      isCalculating: this.isCalculating,
      value: this.value,
      style: { ...this.style },
      comments: this.comments.slice(0),
      needsReload: this.needsReload,
      currencyId: this.currencyId
    });
  }

  public hasComments(): boolean {
    return false;
  }

  public hasFrozen(): boolean {
    return this.hasTag(CELL_TAG_FROZEN);
  }

  public hasLock(): boolean {
    return this.hasTag(CELL_TAG_LOCKED);
  }

  public hasTag(tag: string): boolean {
    return this.tags.includes(tag);
  }

  public addComment(comment: CellComment) {
    this.comments.push(comment);
    this.comments.sort((a, b) => b.date - a.date);
  }
}
