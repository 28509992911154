import { AppState, EpicDependencies } from 'store';
import { requestRefreshGrid, receivedScope, receivedCreateScope } from 'state/scope/Scope.slice';
import { ofType, Epic } from 'redux-observable';
import { concatMap, filter, mergeMap, map, catchError } from 'rxjs/operators';
import { empty, from } from 'rxjs';
import { of } from 'rxjs/index';
import { Observable } from 'rxjs/internal/Observable';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  setMetricsToSummarize,
  requestSummarizedScope,
  receivedSummarizedScope,
  summaryError,
  setSelcetedMacroAnchors
} from './MacroSummaries.slice';
import {
  getAvailableListing,
  getEffeciveGroups,
  PivotOptions,
  getGroupFromConfigItem
} from 'components/PivotConfigurator/utils';
import { getMetricsToSummarize, getVersionsToSummarize } from './MacroSummaries.selector';
import { getScopeReadyData, isReady, ScopeStateUnion } from 'state/scope/Scope.types';
import PivotManager from 'pivot/Pivot.client';
import PivotConfig from 'pivot/PivotConfig';
import { keyBy, flatMap, isNil, isEmpty, mapValues, values, pickBy } from 'lodash';
import { PivotCell } from 'pivot/PivotCell';
import { AxiosInstance } from 'axios';
import { AnyAction } from '@reduxjs/toolkit';
import { setViewConfig } from 'state/ViewConfig/ViewConfig.slice';
import { SettingsState } from 'state/settings/settings.slice';
import { TopMembers } from 'services/Scope.client';
import { planFromSpace } from 'components/Scopebar/ScopeUtils';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.listener';
import { setActiveNonWorkingScope } from 'state/workingSets/nonWorkingSets.slice';

// TODO: figure out how to set this only once
export const setMetricConfigEpic: Epic<any, any, AppState, EpicDependencies> =
  (action$, state$, deps): Observable<AnyAction> | Observable<never> => {
    const client = deps.serviceEnv.axios;
    const macroData = deps.serviceEnv.macroData;
    return action$.pipe(
      ofType(
        LOCATION_CHANGE,
        receivedScope.type,
        setViewConfig.type,
        setActiveNonWorkingScope.type
      ),
      filter((_action) => {
        // make sure we have everything needed to refresh
        const { currentViewId, currentTabId, activeViews, currentViewHasSummary } = state$.value.viewConfigSlice;
        return !isNil(currentViewId) &&
          !isNil(currentTabId) &&
          (
            !isNil(activeViews) &&
            !isEmpty(activeViews)
          ) &&
          currentViewHasSummary;
      }),
      concatMap(_incoming => {
        const { currentViewId, currentTabId, activeViews } = state$.value.viewConfigSlice;
        const maybeActiveTabConfig = activeViews!.find((t) => t.id === currentTabId);

        let sumMets: string[] | undefined = undefined;
        let sumVers: string[] | undefined = undefined;
        let scopeToSummarize: ScopeStateUnion | undefined = undefined;

        if (currentViewId === 'favorite') {
          scopeToSummarize = state$.value.scope;
          sumMets = state$.value.viewConfigSlice.facet!.defaultFavoritesSummaryMetrics.metrics.items;
          sumVers = state$.value.viewConfigSlice.facet!.defaultFavoritesSummaryMetrics.revisions.items;
        } else if (currentViewId && currentViewId.includes('review-')) {
          scopeToSummarize = state$.value.nonWorkingSets.activeNonWorkingScope;
          sumMets = state$.value.viewConfigSlice.facet!.defaultFavoritesSummaryMetrics.metrics.items;
          // TODO: Hard coded review/approve macro summary for now
          sumVers = ['ty-review-approved', 'ty-rp'];
        } else if (maybeActiveTabConfig) {
          scopeToSummarize = state$.value.scope;
          const maybeActiveViewConfig = maybeActiveTabConfig?.views?.find((section) => {
            if (!section || !section.views) { return false; }
            return section.views.find((view) => {
              return view.id === currentViewId;
            });
          })?.views?.find((secview) => {
            return secview.id === currentViewId;
          });
          if (maybeActiveViewConfig && maybeActiveViewConfig.viewParams?.summaryMetrics) {
            sumMets = maybeActiveViewConfig.viewParams?.summaryMetrics.metrics.items;
            sumVers = maybeActiveViewConfig.viewParams?.summaryMetrics.revisions.items;
          }
        }

        const hasSummaryConfig = !!sumVers && !!sumMets;
        if (!sumVers ||
          !sumMets ||
          !scopeToSummarize ||
          !isReady(scopeToSummarize)
        ) {
          return empty();
        }
        if (scopeToSummarize.hasEditableRevision) {
          scopeToSummarize.mainConfig.initializedPlans.forEach((plan) => {
            const maybePm = createPivoteManager(
              state$.value.settings.entriesByKey,
              scopeToSummarize!,
              // @ts-ignore
              plan.space,
              sumMets,
              sumVers,
              client
            );
            if (maybePm) {
              macroData[Number(plan.id)] = maybePm;
            }
          });
        } else {
          const shouldBeReadyScope = scopeToSummarize;
          if (!isReady(shouldBeReadyScope) || !shouldBeReadyScope.currentAnchors) { return empty(); }
          let scopes: Record<string, string[]>[] = [];
          const nonMultiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length === 1);
          const multiScopeDims = pickBy(shouldBeReadyScope.currentAnchors, (ids) => ids.length > 1);
          mapValues(multiScopeDims, (v, k) => {
            v.forEach((id) => {
              scopes.push({
                ...nonMultiScopeDims,
                [k]: [id]
              });
            });
          });
          if(isEmpty(scopes)){ scopes = [shouldBeReadyScope.currentAnchors];}
          scopes.forEach((s) => {
            const macroId = Object.values(s).sort().join('');
            const maybePm = createPivoteManager(
              state$.value.settings.entriesByKey,
              scopeToSummarize!,
              // @ts-ignore
              s,
              sumMets,
              sumVers,
              client
            );
            if (maybePm) {
              macroData[macroId] = maybePm;
            }
          });
        }

        // TODO: reset pm when the settings change
        // may require moving the pm to the component
        const maybeSetSettings = hasSummaryConfig ?
          setMetricsToSummarize(sumMets, [sumVers[0], sumVers[1], sumVers[2]]) :
          null;
        if (maybeSetSettings) {
          return of(maybeSetSettings);
        }
        return empty();
      })
    );
  };

export const requestSummarizedDataEpic: Epic<any, any, AppState, EpicDependencies> =
  (action$, state$, deps): Observable<AnyAction> | Observable<never> => {
    return action$.pipe(
      ofType(
        LOCATION_CHANGE,
        requestSummarizedScope.type,
        requestRefreshGrid.type,
        setSelcetedMacroAnchors.type
      ),
      filter(() => {
        return shouldRefreshSumamry(state$.value);
      }),
      filter(() => {
        return !isNil(state$.value.macroSummaries.selectedMacroAnchors);
      }),
      map((_action) => {
        return !state$.value.viewConfigSlice.currentViewId?.includes('review') ?
          state$.value.scope :
          state$.value.nonWorkingSets.activeNonWorkingScope;
      }),
      filter(inputIsNotNullOrUndefined),
      filter((scope) => isReady(scope)),
      mergeMap((readyScope) => {
        if (!isReady(readyScope) || !state$.value.macroSummaries.selectedMacroAnchors) {
          throw new Error('Scope should be ready here');
        }
        let pm: PivotManager | undefined;
        if (readyScope.hasEditableRevision) {
          const plans = [...readyScope.mainConfig?.initializedPlans, ...readyScope.mainConfig.uninitializedPlans];
          if (isEmpty(plans)) {
            return of(summaryError());
          }
          const currentMacroPlan = planFromSpace(
            plans,
            state$.value.macroSummaries.selectedMacroAnchors
          );
          pm = deps.serviceEnv.macroData[currentMacroPlan.id];
        } else {
          const currentMacroPlan = !state$.value.viewConfigSlice.currentViewId?.includes('review') ?
            Object.values(state$.value.macroSummaries.selectedMacroAnchors).sort().join('') :
            Object.values(readyScope.currentAnchors!).sort().join('');
          pm = deps.serviceEnv.macroData[currentMacroPlan];
        }
        if (!pm) { return empty(); }

        const currentState = state$.value;
        return from(loadSummaryCells(currentState, pm!)).pipe(
          filter(inputIsNotNullOrUndefined),
          map(response => response),
          catchError(error => of(summaryError()))
        );
      }),
    );
  };

const shouldRefreshSumamry = (state: AppState): boolean => {
  const hasSummary = state.viewConfigSlice.currentViewHasSummary;
  const metricsToSummarize = getMetricsToSummarize(state);
  const versionsToSummarize = getVersionsToSummarize(state);

  if (!metricsToSummarize || !versionsToSummarize || !hasSummary) return false;
  return true;
};

function loadSummaryCells(state: AppState, macroPivotManager: PivotManager): Promise<AnyAction | undefined> {
  const hasSummary = state.viewConfigSlice.currentViewHasSummary;
  const metricsToSummarize = getMetricsToSummarize(state); macroPivotManager;
  const versionsToSummarize = getVersionsToSummarize(state);

  if (!macroPivotManager || !metricsToSummarize || !versionsToSummarize || !hasSummary) {
    // should only be called when everything needed is ready
    // eslint-disable-next-line no-console
    console.error('Don\'t have everything needed to get summary');
    return new Promise((resolve, reject) => {
      resolve(undefined);
    });
  }

  const results: PivotCell[] = [];
  const maxCols = macroPivotManager.getColCount();
  const maxRows = macroPivotManager.getRowCount();
  macroPivotManager.forAllPresent((cell) => {
    cell.needsReload = true;
  });
  return macroPivotManager
    .loadMoreCells({
      startRow: 0,
      endRow: maxRows,
      startColumn: 0,
      endColumn: maxCols
    }).then(() => {
      for (let r = 0; r <= maxRows; r++) {
        for (let c = 0; c < maxCols; c++) {
          const cell = macroPivotManager.getCell(r, c);
          if (cell) {
            results.push(cell);
          }
        }
      }
      return receivedSummarizedScope(results);
    }).catch((err) => {
      return summaryError;
    });
}

function createPivoteManager(
  settings: SettingsState['entriesByKey'],
  scopeToSummarize: ScopeStateUnion,
  selectedMacroAnchors: TopMembers,
  metricsToSummarize: string[],
  versionsToSummarize: string[],
  client: AxiosInstance
) {
  const readyData = getScopeReadyData(scopeToSummarize);
  if (readyData === undefined) {
    return undefined;
  }
  const mainConfig = readyData.mainConfig;
  const scopeId = mainConfig.id;
  if (scopeId === undefined) {
    return undefined;
  }
  const all = getAvailableListing(mainConfig, settings);

  const deltaVersion = `${versionsToSummarize![0]}||${versionsToSummarize![1]}||percentage`;

  const multiScopeDims = pickBy(readyData.currentAnchors, (ids) => ids.length >= 2);
  const selectedSpace = mapValues(multiScopeDims, (ids, dimension) => {
    return ids.map((id) => {
      return selectedMacroAnchors[dimension].includes(id) ? { id, selected: true } : { id, selected: false };
    });
  });
  const topMembersAsPivotConfig = values(mapValues(selectedSpace, (v, k) => {
    return {
      dimension: k,
      items: v.filter((i) => i.selected).map((i) => i.id)
    };
  }));
  const rows = [{
    dimension: 'revisions',
    items: [...versionsToSummarize, deltaVersion]
  }, ...topMembersAsPivotConfig];
  const columns = [{
    dimension: 'metrics',
    items: metricsToSummarize
  }];
  const viewParams: PivotOptions = {
    rows,
    columns
  };
  const effective = getEffeciveGroups(
    all,
    viewParams
  );
  const levelsMap = keyBy(flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map(mi =>
    getGroupFromConfigItem(mi, viewParams, levelsMap)
  );
  const pivotCols = effective.column.map(mi =>
    getGroupFromConfigItem(mi, viewParams, levelsMap)
  );
  if (isEmpty(pivotCols) || isEmpty(pivotRows)) {
    // Safety check for invalid pivots being called
    // TODO: report and throw error here
    return undefined;
  }
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols
  });
  const pm = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: `summaryPivot${JSON.stringify(selectedMacroAnchors)}`
  });
  return pm;
}
