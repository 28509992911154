import {
  SCOPETYPE_ACTUALS,
  SCOPETYPE_PLAN,
  SCOPETYPE_SUBMITTED,
  SCOPETYPE_APPROVED,
  SCOPETYPE_WORKING,
  TIME,
  LOCATION,
  PRODUCT,
  PerspectivePaths,
  PERSPECTIVE_PATHS
} from '../../utils/domain/constants';
import { TopMembers } from '../../services/Scope.client';
import { PRODLIFE } from 'utils/domain/constants';
import * as T from 'io-ts';
import { makeTreeTypeOf } from 'utils/types';
import { getPrimary } from 'components/PivotConfigurator/utils';
import { TSpace, map as mapSpace } from 'space';
import { pipe } from 'fp-ts/lib/pipeable';
import { SeedPlan, SeedActuals } from './ScopeManagement.slice';
import { findIndex } from 'lodash';
import { PlanId, PlanMetadata } from './codecs/PlanMetadata';
import { Workflows } from './codecs/Workflows';
import { CONTEXT_READY, CONTEXT_PENDING, CONTEXT_FAILED, CONTEXT_BUSY } from 'state/workingSets/workingSets.types';

type dims = typeof TIME | typeof PRODUCT | typeof LOCATION | typeof PRODLIFE
export type ScopeRoot = {
  // there are 4 dimensions, add them if you need them
  [key in dims]: string
}

// eslint-disable-next-line max-len
export type ScopeType = typeof SCOPETYPE_ACTUALS | typeof SCOPETYPE_PLAN | typeof SCOPETYPE_SUBMITTED | typeof SCOPETYPE_APPROVED | typeof SCOPETYPE_WORKING

const TFormats = T.partial({
  standard: T.union([T.string, T.undefined]),
  summary: T.union([T.string, T.undefined]),
  excelDataCell: T.union([T.string, T.undefined]),
  variancePercentGrid: T.union([T.string, T.undefined]),
  percentTotalGrid: T.union([T.string, T.undefined]),
  percentGrandTotalGrid: T.union([T.string, T.undefined])
});

export type Formats = T.TypeOf<typeof TFormats>;

const TServerMetric = T.type({
  id: T.string,
  name: T.string,
  units: T.union([T.literal('monetary'), T.literal('bare'), T.literal('percent')]),
  hidden: T.boolean, // this becomes ConfigItem.always hidden, because they should never show up
  formats: T.union([TFormats, T.undefined]),
  editable: T.boolean,
  group: T.string
});

export type ServerMetric = T.TypeOf<typeof TServerMetric>;

export const TServerScopeMember = T.type({
  id: T.string,
  name: T.string,
  description: T.string,
  level: T.string
});

export type ServerScopeMember = T.TypeOf<typeof TServerScopeMember>;

export interface ServerSeedOptions {
  seedPeriod: string,
  seedVersion: string,
  seedSource: string,
  seedVersionLabel: string
}
export interface ServerSeed {
  module: string,
  period: string,
  seedOptions: ServerSeedOptions[]
}

export type PersistMessage = {
  [scopeId: string]: PersistStatus
}

export type PersistStatus = {
  inflight: number,
  previousError: string | undefined
}

const TServerNestedScopeMember = makeTreeTypeOf(TServerScopeMember, 'ServerScopeMemberTree');

export type ServerNestedScopeMember = T.TypeOf<typeof TServerNestedScopeMember>;

export const SCOPE_READY = 'ScopeReady';
export const SCOPE_NOT_READY = 'ScopeNotReady';

export const TScopeStatus = T.union([
  T.literal(CONTEXT_READY),
  T.literal(CONTEXT_PENDING),
  T.literal(CONTEXT_FAILED),
  T.literal(CONTEXT_BUSY)
]);

const TServerScopeResponseBase = T.type({
  id: T.string,
  status: TScopeStatus
});

export function THierDataOf<Type extends T.Mixed>(of: Type): T.Type<HierData<T.TypeOf<Type>>> {
  return T.type({
    id: T.string,
    primary: T.boolean,
    data: of
  });
}

export type HierData<T> = { id: string, primary: boolean, data: T }


export const TLevel = T.type({
  id: T.string,
  name: T.string,
  description: T.union([T.string, T.undefined])
});


const hasHidden = T.partial({ hidden: T.boolean });

// Making these a single type for the moment
// Perhaps it makes sense to have one defined for each because they are logically different
export const TVersionBacked = T.intersection([hasHidden, T.type({
  type: T.union([
    T.literal('SingleVersion'),
    T.literal('PercentToTotal'),
    T.literal('PercentToGrandTotal')]
  ),
  version: T.string
})]);

export const TCompositeBacked = T.intersection([hasHidden, T.type({
  type: T.literal('VarianceVersion'),
  version: T.string,
  against: T.string,
  varType: T.union([T.literal('delta'), T.literal('percentage')])
})]);

export const TRevision = T.union([TVersionBacked, TCompositeBacked]);

const TServerReady = T.type({
  type: T.literal(SCOPE_READY),
  scopeReady: T.literal(true),
  inSeason: T.string,
  initialized: T.boolean,
  // These 3 are spaces each containing hierarchy id maps to the data on that hierarchy
  // i.e. levels: each dimension has some hierarchies each of which contains levels
  // todo... implement something like 'non-empty record'
  levels: TSpace(T.readonlyArray(THierDataOf(T.array(TLevel)))),
  memberTrees: TSpace(T.readonlyArray(THierDataOf(T.readonlyArray(TServerNestedScopeMember)))),
  members: TSpace(T.readonlyArray(TServerScopeMember)),
  metrics: T.readonlyArray(TServerMetric),
  revisions: T.readonlyArray(TRevision),
  workflow: T.union([T.literal('in-season'), T.literal('pre-season'), T.nullType]),
  initializedPlans: T.readonlyArray(PlanMetadata),
  uninitializedPlans: T.readonlyArray(PlanMetadata)
});

const TServerScope = T.intersection([TServerScopeResponseBase, TServerReady]);

export type ServerScope = T.TypeOf<typeof TServerScope>;

const TServerNotReady = T.type({
  type: T.literal(SCOPE_NOT_READY),
  scopeReady: T.literal(false)
});

const TServerScopeNotReady = T.intersection([TServerScopeResponseBase, TServerNotReady]);

export type ServerScopeNotReady = T.TypeOf<typeof TServerScopeNotReady>;

export const TServerScopeResponse = T.union([TServerScope, TServerScopeNotReady]);

export type ServerScopeResponse = T.TypeOf<typeof TServerScopeResponse>

// TODO: Use the type discriminator instead of the boolean
// Remove the boolean
export const isScopeReady = (scope: ServerScopeResponse | undefined | null): scope is ServerScope => {
  return scope ? scope.scopeReady === true : false;
};
export const isScopeNotReady = (scope: ServerScopeResponse | undefined | null): scope is ServerScopeNotReady => {
  return scope ? scope.scopeReady === false : false;
};

export const GRID_SAVING = 'Saving...' as const;
export const GRID_SAVED = 'Saved' as const;
export const GRID_REFRESHING = 'Refreshing...' as const;
export const GRID_REFRESHED = 'Refreshed' as const;
export const GRID_ERROR = 'An error has occured' as const;
export const GRID_DEFAULT = ' ' as const;

export type GridAsyncState =
  typeof GRID_SAVING |
  typeof GRID_SAVED |
  typeof GRID_REFRESHING |
  typeof GRID_REFRESHED |
  typeof GRID_ERROR |
  typeof GRID_DEFAULT  // length one string is the initial state

/**
 * The type o possible scope states
 */
export enum ScopeStateTag {
  // The context has been requested but we do not yet have an id
  // No data is yet available
  Empty,
  // The context has been requested and the server is initializing
  // Minimal data is available beyond an id
  Pending,
  // The context has entered a failed state
  // Some date may be available and this may be recoverable
  Failed,
  // The context is currently processing a request
  // Data is available
  Busy,
  // The context is ready for all operations
  // Data is available
  Ready
}

export interface ScopeEmpty {
  readonly _tag: ScopeStateTag.Empty
}

export const scopeEmpty: ScopeEmpty = {
  _tag: ScopeStateTag.Empty
} as const;

export interface ScopePending {
  readonly _tag: ScopeStateTag.Pending,
  readonly id: string,
  readonly isFetching: true
}

export function scopePending(id: string): ScopePending {
  return {
    _tag: ScopeStateTag.Pending,
    isFetching: true,
    id
  } as const;
}

export interface ScopeFailed {
  readonly _tag: ScopeStateTag.Failed,
  readonly id: string,
  // We possibly have stale data
  readonly previous: ScopeReadyData | undefined
}

export function scopeFailed(id: string, previous: ScopeReadyData | undefined = undefined): ScopeFailed {
  return {
    _tag: ScopeStateTag.Failed,
    id,
    previous
  } as const;
}

export type ScopeReady = Readonly<{
  _tag: ScopeStateTag.Ready,
  id: string,
  isFetching: boolean,
  isMultiScope: boolean,
  inSeason: string,
  mainConfig: ServerScope,
  currentAnchors: TopMembers | undefined,
  initialized: boolean,
  forceRefreshGrid: boolean,
  hasEditableRevision: boolean,
  gridAsyncState: GridAsyncState,
  hasLocks: boolean,
  eopOptions: Record<number, (SeedPlan | SeedActuals)[]>, // TODO: just make this default to empty set
  importOptions: Record<number, PlanMetadata[]>,
  overlayOptions: Record<number, string[]>,
  seedOptions: Record<number, (SeedPlan | SeedActuals)[]>, // TODO: just make this default to empty set
  workflows: Record<number, Workflows['plans']>, // TODO: maybe filter this down to something else on ingestion?
  persistErrorStatus: string | undefined,
  message: string | undefined, // not always a message present
  lastDynamicId?: string
}>

// For cases when we are in busy or failed and may have some historical data
export type ScopeReadyData = Omit<ScopeReady, 'id' | '_tag'>;

export function scopeReady(id: string, body: ScopeReadyData): ScopeReady {
  return {
    ...body,
    _tag: ScopeStateTag.Ready,
    id
  } as const;
}

export type ScopeBusy = {
  readonly _tag: ScopeStateTag.Busy,
  readonly id: string,
  readonly previous: ScopeReadyData
}

export function scopeBusy(id: string, previous: ScopeReadyData): ScopeBusy {
  return {
    _tag: ScopeStateTag.Busy,
    id,
    previous
  } as const;
}

export type ScopeStateUnion =
  ScopeEmpty
  | ScopePending
  | ScopeFailed
  | ScopeReady
  | ScopeBusy;

export function isEmpty(s: ScopeStateUnion): s is ScopeEmpty {
  return s._tag === ScopeStateTag.Empty;
}

export function isPending(s: ScopeStateUnion): s is ScopePending {
  return s._tag === ScopeStateTag.Pending;
}

export function isFailed(s: ScopeStateUnion): s is ScopeFailed {
  return s._tag === ScopeStateTag.Failed;
}

export function isReady(s: ScopeStateUnion): s is ScopeReady {
  return s._tag === ScopeStateTag.Ready;
}

export function isBusy(s: ScopeStateUnion): s is ScopeBusy {
  return s._tag === ScopeStateTag.Busy;
}

export function hasScopeId(s: ScopeStateUnion): s is ScopeReady | ScopeBusy | ScopePending {
  return isReady(s) || isBusy(s) || isPending(s);
}

/**
 * Attempt to manipulate the scope ready data.
 *
 * This is a no-op if there is no ScopeReadyData extractable from the current input
 * @param f
 */
export function modifyScopeData(u: ScopeStateUnion, mapper: (srd: ScopeReadyData) => ScopeReadyData): ScopeStateUnion {
  if (isReady(u)) {
    return { ...u, ...mapper(u) };
  } else if (isBusy(u)) {
    return { ...u, previous: mapper(u.previous) };
  } else if (isFailed(u) && u.previous) {
    return { ...u, previous: mapper(u.previous) };
  }
  return u;
}

export function modifyScopeDataWithId(
  u: ScopeStateUnion,
  mapper: (id: string, srd: ScopeReadyData) => ScopeReadyData
): ScopeStateUnion {
  if (isReady(u)) {
    return { ...u, ...mapper(u.id, u) };
  } else if (isBusy(u)) {
    return { ...u, previous: mapper(u.id, u.previous) };
  } else if (isFailed(u) && u.previous) {
    return { ...u, previous: mapper(u.id, u.previous) };
  }
  return u;
}

export function getScopeReadyData(u: ScopeStateUnion): ScopeReadyData | undefined {
  if (isReady(u)) {
    return u;
  } else if (isFailed(u) || isBusy(u)) {
    return u.previous;
  }
  return undefined;
}

export function getScopeId(u: ScopeStateUnion): string | undefined {
  if (isEmpty(u)) {
    return;
  }
  return u.id;
}

/**
 * Extract the top member id from a server scope
 * @param scope
 */
export function getTopMembers(scope: ScopeReadyData): TopMembers {
  return pipe(scope.mainConfig.memberTrees, mapSpace(t => getPrimary(t).map(hier => hier.v.id)));
}

export const isValidPerspectivePath = (path: string): path is PerspectivePaths => {
  return findIndex(PERSPECTIVE_PATHS, (p) => p === path) >= 0;
};

export const maybeGetCurrentScopeTimeId = (scope: ScopeReady | ScopeBusy | ServerScope): string => {
  if ('_tag' in scope) {
    return isReady(scope) ?
      scope.mainConfig.memberTrees.time[0].data[0].v.id :
      scope.previous.mainConfig.memberTrees.time[0].data[0].v.id;
  }
  return scope.memberTrees.time[0].data[0].v.id;
};
